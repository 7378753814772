.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 100px;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.receiver-details {
	clear:both;
	text-align: left;
}
.receiver_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableReceivers {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
    width: 100%;
}

.upc_search {
	float:right; 
	margin-right:20px;
	width: 200px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}


input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }

.align-center {
	text-align: center;
}

th { border-bottom:1px dashed #333333;width:100px; }

.row { padding: 5px; }

.right { text-align: right; }

.nowrap {
	white-space: nowrap !important;
}

.span-buttons { 
	float:left;	
}

.span-reader { 
	float:right;	
	padding-right: 50px;
}

.text-primary {
	padding-left: 0px;
}

.btn-sm {
	margin-right: 2px;
}

.ReactTable .rt-noData {
	z-index: 0;
}

