@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400);
body {
  background-color: rgb(255, 255, 255);
}

.app {
  display: flex;
  justify-content: left;
}

.app .screen {
  width: 1680px;
}

.p-10 {
  cursor: pointer;
  padding-left: 40px;
  padding-top: 20px;
}


.text-primary {
  padding-left: 10px;
}

.status-count {
  margin-left: 40px;
}

.card-title {
  margin-bottom: 0; 
}

.print-orders {
	float:right;
}

.card-title .fa {
  transition: .3s transform ease-in-out;
  float: right;
}
.card-title .collapsed .fa {
  transform: rotate(90deg);
}

.panel-title {
  font-size: 12px;
  margin-bottom: 0;
  padding: 14px 14px 4px 20px;
  color: #607d8b;
  font-weight: 600;
  text-transform: uppercase;
}
.clientselect {
	padding-top:10px
}
.form-control {
	height: 35px;
	margin-top: 10px;
}

label {
    padding-right: 0px !important;
}

.form-label {
	color: blue !important;
}

.right {
	text-align: right !important;
}

.btn-primary {
	float:none;
	margin-top: 0px;
	margin-right: 10px;
}

.page-titles {
	margin-top: 10px;	
}
.order-details {
	clear:both;	
}

.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 140px;
    width: 95%;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
	text-align: left;
}
.header-tbl-left {
	width: 300px;
}
.header-tbl-right {
	width: 400px;
}

.threecol {
	margin-left: 5px;
	width:75%;
	text-align: left;
}
.section-title {
	padding-left: 20px;
}

.row-footer {
	margin-left: 20px;
}


@media print {
    .page-break-after {
        clear: both;
		page-break-after: always;
		min-height: 1px;
	}
}

@media print { 
	.noprint { 
	   visibility: hidden; 
	} 
 } 

.mobile {
	margin-left: 0px !important;
}

.form-input-lg {
	width:200px;
}

.ReactTable {
	cursor: pointer;
}
h4 {
	font-size: 14px;
	line-height: 14px;
}

.rt-td input[type=checkbox] {
	opacity: 0;
  }

.print {
	margin: 50px;
}

.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 140px;
    width: 95%;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.order-details {
	clear:both;
	text-align: left;
}
.order_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableOrders {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
}

.upc_search {
	float:right; 
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}

.dropClients {
	margin-right: 20px;
}

.dropStates {
	float: left;
    width: 200px;
    margin-bottom: 5px;
    margin-top: 5x;
    clear:both;
}

.customer-drop {
    float: left;
    width: 50%;
    margin-bottom: 10px;
    margin-top: 10px;
    clear:both;
}

.order_items {
	clear: both;
}

.btn-div {
	margin-top:40px;
	width: 75%;
	text-align: left;
}

.btn-primary { 
	float: left;
	margin-top: 20px;
}

.qty_field { 
	width:50px;
	margin-right: 10px;
	margin-left: 10px;
	
}

.divSCANNED {
	font-weight: bold;
	color: darkblue;
}

.btn-remove-item {
	float: right;
}

.table-order-items {
	width: 100%;
	padding: 5px;

}

.align-right {
	text-align: right;
}

.input-ship-cost {
	width: 100px;
}


.tableprintnew {
	font-size: 14pt;
	line-height: 20px;
	padding: 20px;
	border-collapse:separate; 
    border-spacing:0 25px;       
    width: 80% !important;  
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
@media print {
    .page-break-after {
        clear: both;
		page-break-after: always;
		min-height: 1px;
	}
}

@media print { 
	.noprint { 
	   visibility: hidden; 
	} 
 } 

.mobile {
	margin-left: 0px !important;
}

.form-input-lg {
	width:200px;
}

.ReactTable {
	cursor: pointer;
}
h4 {
	font-size: 14px;
	line-height: 14px;
}

.rt-td input[type=checkbox] {
	opacity: 0;
  }

.print {
	margin: 50px;
}

.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 140px;
    width: 95%;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.order-details {
	clear:both;
	text-align: left;
}
.order_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableOrders {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
}

.upc_search {
	float:right; 
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}

.dropClients {
	margin-right: 20px;
}

.dropStates {
	float: left;
    width: 200px;
    margin-bottom: 5px;
    margin-top: 5x;
    clear:both;
}

.customer-drop {
    float: left;
    width: 50%;
    margin-bottom: 10px;
    margin-top: 10px;
    clear:both;
}

.order_items {
	clear: both;
}

.btn-div {
	margin-top:40px;
	width: 75%;
	text-align: left;
}

.btn-primary { 
	float: left;
	margin-top: 20px;
}

.qty_field { 
	width:50px;
	margin-right: 10px;
	margin-left: 10px;
	
}

.divSCANNED {
	font-weight: bold;
	color: darkblue;
}

.btn-remove-item {
	float: right;
}

.table-order-items {
	width: 100%;
	padding: 5px;

}

.align-right {
	text-align: right;
}

.input-ship-cost {
	width: 100px;
}


.tableprintnew {
	font-size: 14pt;
	line-height: 20px;
	padding: 20px;
	border-collapse:separate; 
    border-spacing:0 25px;       
    width: 80% !important;  
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }

#footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}
@media print {
    .page-break-after {
        clear: both;
		page-break-after: always;
		min-height: 1px;
	}
}

@media print { 
	.noprint { 
	   visibility: hidden; 
	} 
 } 

.form-input-lg {
	width:200px;
}

.ReactTable {
	cursor: pointer;
}
h4 {
	font-size: 14px;
	line-height: 14px;
}

.rt-td input[type=checkbox] {
	opacity: 0;
  }

.print {
	margin: 50px;
}

.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 140px;
    width: 95%;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.order-details {
	clear:both;
	text-align: left;
}
.order_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableOrders {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
}

.upc_search {
	float:right; 
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}

.dropClients {
	margin-right: 20px;
}

.dropStates {
	float: left;
    width: 200px;
    margin-bottom: 5px;
    margin-top: 5x;
    clear:both;
}

.customer-drop {
    float: left;
    width: 50%;
    margin-bottom: 10px;
    margin-top: 10px;
    clear:both;
}

.order_items {
	clear: both;
}

.btn-div {
	margin-top:40px;
	width: 75%;
	text-align: left;
}

.btn-primary { 
	float: left;
	margin-top: 20px;
}

.qty_field { 
	width:50px;
	margin-right: 10px;
	margin-left: 10px;
	
}

.divSCANNED {
	font-weight: bold;
	color: darkblue;
}

.btn-remove-item {
	float: right;
}

.table-order-items {
	width: 100%;
	padding: 5px;

}

.align-right {
	text-align: right;
}

.input-ship-cost {
	width: 100px;
}


.tableprintnew {
	font-size: 14pt;
	line-height: 20px;
	padding: 20px;
	border-collapse:separate; 
    border-spacing:0 25px;       
    width: 80% !important;  
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
@media all {
	.page-break	{ display: none; }
	margin-top: 20px;
}

@media print {
	.page-break	{ display: block; page-break-before: always; }
	margin-top: 20px;
}

.ReactTable {
	cursor: pointer;
}
h4 {
	font-size: 14px;
	line-height: 14px;
}

.print {
	margin: 50px;
}

.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 140px;
    width: 95%;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.order-details {
	clear:both;
	text-align: left;
}
.order_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableOrders {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
}

.upc_search {
	float:right; 
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}

.dropClients {
	margin-right: 20px;
}

.dropStates {
	float: left;
    width: 200px;
    margin-bottom: 5px;
    margin-top: 5x;
    clear:both;
}

.product-drop {
    float: left;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
    clear:both;
}

.order_items {
	clear: both;
}

.btn-div {
	margin-top:40px;
	width: 75%;
	text-align: right;
}

.btn-primary { 
	float: left;
	margin-top: 20px;
}

.qty_field { 
	width:50px;
	margin-right: 10px;
	margin-left: 10px;
	
}

.btn-remove-item {
	float: right;
}

.table-order-items {
	width: 100%;
}

.align-right {
	text-align: right;
}

.input-ship-cost {
	width: 100px;
}

.label-order-total {

}

.tableprint {
	font-size: 9pt;
	line-height: 20px;
	padding: 20px;
	border-collapse:separate; 
    border-spacing:0 25px;       
    width: 100% !important;  
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
.label { 
    page-break-after: always; 
    /*font-stretch: extra-expanded;*/
    font-size: 5.5em;
    padding-top: 25px;
    padding-bottom: 15px;
    color:black;

    width: auto; 
	border: 0; 
	margin-left: -50px;
    /*padding-top: 15px;*/    
    padding-left: -50px;
	float: none !important; 
} 

.label-product { 
    page-break-after: always; 
    /*font-stretch: extra-expanded;*/
    font-size: 3.5em;
    padding-top: 0px;
    /*padding-bottom: 15px;*/
    color:black;
    text-align: left;
    width: auto; 
	border: 0; 
	margin-left: 0px;
    /*padding-top: 15px;*/    
    padding-left: 0px;
	float: none !important; 
} 


.dropClients {
	max-width: 225px;
	min-width: 200px;
	float: right;
}

.priority_field {
	width: 50px;
}

.modalMessage { 
	color: #FF0000;
}

.inventory-table {
	border: 1px solid black;
	
}

#customers {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
  }
  
  #customers td, #customers th {
	border: 1px solid #ddd;
	padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	background-color: #bfbfbf;
	color: black;
  }

.form-input {
	width: 100%;
	padding: 3px 3px;
	margin: 2px 0;
	box-sizing: border-box;
  }

.prod-sku {
	width: 300px;
}

.prod-desc {
	width: 300px;
}

.num-desc {
	width: 75px;
}


.btn-div {
	margin-top: 0px;
	margin-left: 15px;
}

.table-product-bins {
	padding: 20px;
	font-size: 24px;	
	width: 90%;
}
.table-product-bins-header {
	padding: 20px;
	font-size: 24px;	
	width: 90%;
}

.table-product-bins tr:nth-child(odd) {
	background-color: lightgray;

}

.td-bn-rem, .td-bn-qty {
	float: right;
}

.tool-link {
	font-size: 10px;
}

.td-oi-qty {
	text-align: center;
}

.print {
	width: 100%;
}

.frame {
	height: 400px;
	overflow-y: auto;
}

.text-primary a {
	color: #007bff!important;
}
.refresh { 

  background-color: #5c4ac7; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 2px 4px; /* Some padding */
  font-size: 12px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */

}

.refresh:hover {
  background-color: #5c4ac7; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
}

.qoh-field {
	width: 50px;
}

.qoh-field-100 {
	width: 100px;
}

.form-label {
	font-weight: bold;
}
/*
.label-product {
	margin-left: -200px;
}
*/
.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 100px;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.receiver-details {
	clear:both;
	text-align: left;
}
.receiver_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableReceivers {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
    width: 100%;
}

.upc_search {
	float:right; 
	margin-right:20px;
	width: 200px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}


input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }

.align-center {
	text-align: center;
}

th { border-bottom:1px dashed #333333;width:100px; }

.row { padding: 5px; }

.right { text-align: right; }

.nowrap {
	white-space: nowrap !important;
}

.span-buttons { 
	float:left;	
}

.span-reader { 
	float:right;	
	padding-right: 50px;
}

.text-primary {
	padding-left: 0px;
}

.btn-sm {
	margin-right: 2px;
}

.ReactTable .rt-noData {
	z-index: 0;
}



label {
    padding-right: 0px !important;
}

.form-label {
	color: #007bff !important;
}

.btn-primary {
	float:none;
	margin-top: 0px;
	margin-right: 10px;
}

.indent-left {
  padding-left:20px;
}

.text-sm { 
    width: 50px;
 }

.main {
  display: flex;
  margin: 0;
  justify-content:center;
  padding: 20px;
  flex-direction: row;
}

.timeClock {
  padding: 20px;
  background-color: #e0ebeb;
  display: flex;
  justify-content: center;
}

.employeeInfo {
   display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 15px;
  color: #007bff;
}

.employeePicture {
  width: 150px;
  border-radius: 100px;
  align-self: center;
}

.employeeName {
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  text-align: center;
  margin: 10px 0 0 0;
  color: #007bff;
}

.employeeTitle {
  text-align: center;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  margin: -5px 0 10px 0;
  color: #007bff;
}

.timeDisplay {  
  display: flex;
  flex-direction: column;
  width: 450px;
  background-color: #e0ebeb;
  padding: 20px;
}

.currentTime {
  text-align: center;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: #007bff;
  margin-top: 2px;
}

.workBtn {
	margin-top: 20px;
}

.centerBlock {
  display: flex;
  /*justify-content: center;*/
}

.timeContainer {
  background-color: #fff;
  height: 50px;
  overflow: scroll;
}

.clockInTime, .clockOutTime {
  font-size: 16px;
  text-align: center;
  list-style: none;
}

.listMain {
  width: 300px;
}

#list3 {
  text-align: center;
  list-style: none;
}

ul {
  margin: 0;
}

#clockIn {
	width: 100px;
	float:left;
}

#clockOut {
	width: 100px;
	float:right;
}

.dropType {
  width: 200px;
}



label {
    padding-right: 0px !important;
}

.form-label {
	color: blue !important;
}

.btn-primary {
	float:none;
	margin-top: 0px;
	margin-right: 10px;
}

label {
    padding-right: 0px !important;
}

.form-label {
	color: blue !important;
}

.btn-primary {
	float:none;
	margin-top: 0px;
	margin-right: 10px;
}
.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 100px;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.return-details {
	clear:both;
	text-align: left;
}
.return_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableReturns {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
    width: 100%;
}

.upc_search {
	float:right; 
	margin-right:20px;
	width: 200px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}


input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }

.align-center {
	text-align: center;
}

th { border-bottom:1px dashed #333333;width:100px; }

.row { padding: 5px; }

.right { text-align: right; }

.nowrap {
	white-space: nowrap !important;
}

.span-buttons { 
	float:left;	
}

.span-reader { 
	float:right;	
	padding-right: 50px;
}

.text-primary {
	padding-left: 0px;
}

.btn-sm {
	margin-right: 2px;
}

.ReactTable .rt-noData {
	z-index: 0;
}


.label { 
    page-break-after: always; 
    /*font-stretch: extra-expanded;*/
    font-size: 5.5em;
    padding-top: 25px;
    padding-bottom: 15px;
    color:black;

    width: auto; 
	border: 0; 
	margin-left: -50px;
    /*padding-top: 15px;*/    
    padding-left: -50px;
	float: none !important; 
} 

.label-product { 
    page-break-after: always; 
    /*font-stretch: extra-expanded;*/
    font-size: 3.5em;
    padding-top: 0px;
    /*padding-bottom: 15px;*/
    color:black;
    text-align: left;
    width: auto; 
	border: 0; 
	margin-left: 0px;
    /*padding-top: 15px;*/    
    padding-left: 0px;
	float: none !important; 
} 


@media all {
	.page-break	{ display: none; }
	margin-top: 20px;
}

@media print {
	.page-break	{ display: block; page-break-before: always; }
	margin-top: 20px;
}

.ReactTable {
	cursor: pointer;
}
h4 {
	font-size: 14px;
	line-height: 14px;
}

.print {
	margin: 50px;
}

.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 140px;
    width: 95%;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.order-details {
	clear:both;
	text-align: left;
}
.order_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableOrders {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
}

.upc_search {
	float:right; 
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}

.dropClients {
	margin-right: 20px;
}

.dropStates {
	float: left;
    width: 200px;
    margin-bottom: 5px;
    margin-top: 5x;
    clear:both;
}

.product-drop {
    float: left;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
    clear:both;
}

.order_items {
	clear: both;
}

.btn-div {
	margin-top:40px;
	width: 75%;
	text-align: right;
}


.qty_field { 
	width:50px;
	margin-right: 10px;
	margin-left: 10px;
	
}

.btn-remove-item {
	float: right;
}

.table-order-items {
	width: 100%;
}

.align-right {
	text-align: right;
}

.input-ship-cost {
	width: 100px;
}

.label-order-total {

}

.tableprint {
	font-size: 9pt;
	line-height: 20px;
	padding: 20px;
	border-collapse:separate; 
    border-spacing:0 25px;       
    width: 100% !important;  
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
.invoice-info p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}
#invoice-top {
    min-height: 100px;
}
.title p {
	color: #67757c;
	font-size: 14px;
	font-family: sans-serif;
}

.title h4 {
  color: #455a64;
  text-align: left;
}

.receiver-details {
	clear:both;
	text-align: left;
}
.receiver_list {
	text-align: left;
	font-size: 11pt;
	color: blue;
}

.tableReceivers {
	text-align: left;
	border-collapse: separate;
    border-spacing: 15px;
    width: 100%;
}

.upc_search {
	float:right; 
	margin-right:20px;
	width: 200px;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 10pt;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 10pt;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 10pt;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 10pt;
}

.scanned {
	background-color: lightblue;
}

.scanComplete {
	background-color: lightgreen;
}


input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }

.align-center {
	text-align: center;
}

th { border-bottom:1px dashed #333333;width:100px; }

.row { padding: 5px; }

.right { text-align: right; }

.nowrap {
	white-space: nowrap !important;
}

.span-buttons { 
	float:left;	
}

.span-reader { 
	float:right;	
	padding-right: 50px;
}

.text-primary {
	padding-left: 0px;
}

.btn-sm {
	margin-right: 2px;
}

.ReactTable .rt-noData {
	z-index: 0;
}


body {
  font-family: 'Roboto', sans-serif;
  margin: 0px;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: .6em;
  height: .6em;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: rgb(128, 128, 128);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

