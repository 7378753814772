body {
  background-color: rgb(255, 255, 255);
}

.app {
  display: flex;
  justify-content: left;
}

.app .screen {
  width: 1680px;
}
