.label { 
    page-break-after: always; 
    /*font-stretch: extra-expanded;*/
    font-size: 5.5em;
    padding-top: 25px;
    padding-bottom: 15px;
    color:black;

    width: auto; 
	border: 0; 
	margin-left: -50px;
    /*padding-top: 15px;*/    
    padding-left: -50px;
	float: none !important; 
} 

.label-product { 
    page-break-after: always; 
    /*font-stretch: extra-expanded;*/
    font-size: 3.5em;
    padding-top: 0px;
    /*padding-bottom: 15px;*/
    color:black;
    text-align: left;
    width: auto; 
	border: 0; 
	margin-left: 0px;
    /*padding-top: 15px;*/    
    padding-left: 0px;
	float: none !important; 
} 

